import React from "react";
import { Link } from "gatsby";
import * as styles from "./filterBarSection.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export default class FilterBarSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    render() {
        return (
            <div className={styles.container}>
                <div
                    className={`
                    ${styles.content}
                    ${this.props.flexWrap && styles.content_flex_wrap}
                `}
                >
                    <div
                        className={`
                        ${styles.headerMobile}
                        v_mobile 
                        v_tablet`}
                    >
                        {!!this.props.header && <h6 className={"caps_and_spaced no_wrap"}>{this.props.header}</h6>}
                    </div>
                    {this.props.children}
                </div>
                <div
                    className={`
                    ${styles.header}
                    v_desktop
                `}
                >
                    {!!this.props.header && <h6 className={"caps_and_spaced no_wrap rotate_90"}>{this.props.header}</h6>}
                </div>
            </div>
        );
    }
}
