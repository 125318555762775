import React from "react";
import { withAuthentication } from "../../hoc/withAuthentication";
import * as styles from "./index.module.css";
import Main from "../../components/main";
import ContentArea from "../../components/contentArea";
import FilterBar from "../../components/filterBar";
import PageSplit from "../../components/pageSplit";
import IconButton from "../../components/iconButton";
import MasonryTiles from "../../components/masonryTiles";
import Input from "../../components/input";
import Banner from "../../components/banner";
import TextArea from "../../components/textArea";
import NavPanel from "../../components/navPanel";
import Consts from "../../config/consts";
import Api from "../../config/api";
import {
    faClock,
    faArrowUp,
    faArrowDown,
    faPaintBrush,
    faGlobeEurope,
    faGamepad,
    faTheaterMasks,
    faMusic,
    faCameraRetro,
    faGlobe,
    faWrench,
    faQuidditch,
    faHeart,
    faSearch,
} from "@fortawesome/free-solid-svg-icons";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED, Consts.AUTH_LEVELS.UNAUTHENTICATED], // INDEX MUST HAVE BOTH AUTH AND UNAUTH IN ORDER TO AVOID FAILURE LOOPING
        redirectPage: "/",
    },
    class Explore extends React.Component {
        // export default class Home extends React.Component {
        constructor(props) {
            super(props);
            this.main_ref = React.createRef();
            this.apiDelayTimer = null;

            // Grab the query params from the url
            const params = new URLSearchParams(props.location.search);

            // Grab out the genres param if any
            let selectedGenres = params.get("genre");
            selectedGenres = !!selectedGenres ? selectedGenres.split(",") : [];

            // Grab out the sort param if any
            let selectedSortBy = params.get("sort") || "RECENTLY_ADDED";

            // Grab the listing state from the params
            let selectedListingStates = params.get("states");
            selectedListingStates = !!selectedListingStates ? selectedListingStates.split(",") : ["AUCTION_SALE", "FIXED_PRICE_SALE", "NOT_FOR_SALE"];

            this.state = {
                initialising: false,
                apiDelayMs: 1000,
                windowSize: window.innerWidth,
                user: props.auth.user || null,

                items: [],
                hasRunInitialFetch: false,
                fetchQuantity: 40,
                fetchingMore: false,
                fetchMaxCount: 5,
                fetchCount: 0,
                fetchHasMore: true,
                fetchAllowInfinite: true,

                search: "",
                sortBy: selectedSortBy,
                genres: selectedGenres,
                listingStates: selectedListingStates,
            };
        }

        componentDidMount() {
            let resize_timeout = null;
            window.onresize = (val) => {
                clearTimeout(resize_timeout);
                resize_timeout = setTimeout(() => {
                    this.setState({
                        windowSize: window.innerWidth,
                    });
                }, 200);
            };

            //     // Grab the genres
            //     Api.market({
            //         endpoint: "/genre",
            //         method: "GET",
            //         data: {},
            //     })
            //         .then((res) => {
            //             this.setState({
            //                 genres: res.genres,
            //                 initialising: false,
            //             });
            //         })
            //         .catch((e) => {
            //             this.setState({
            //                 genres: {},
            //                 initialising: false,
            //             });
            //         });
        }

        onReset = () => {
            this.setState({
                search: "",
                sortBy: "RECENTLY_ADDED",
                genres: [],
                listingStates: ["AUCTION_SALE", "FIXED_PRICE_SALE", "NOT_FOR_SALE"],
            });
        };
        onSearchTextChanged = (text) => {
            clearTimeout(this.apiDelayTimer);
            this.apiDelayTimer = setTimeout(() => {
                this.setState({
                    search: text,
                });
            }, this.state.apiDelayMs);
        };

        onShowBidOverlay = (listing) => {
            if (this.main_ref) {
                this.main_ref.current.showBidOverlay(listing);
            }
        };

        onSortByChanged = (selectedSortBy) => {
            this.setState({
                sortBy: selectedSortBy,
            });
        };
        onGenresChanged = (selectedGenres) => {
            console.log(selectedGenres);
            this.setState({
                genres: selectedGenres,
            });
        };
        onListingStateChanged = (selectedStates) => {
            this.setState({
                listingStates: selectedStates,
            });
        };

        render() {
            return (
                <Main
                    ref={this.main_ref}
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <ContentArea slim={true} extraTopPadding={true} bottomRule={true}>
                        <TextArea center={true}>
                            <div className={styles.filter_container}>
                                <Input
                                    icon={faSearch}
                                    //className={`${styles.input}`}
                                    defaultValue={this.state.search}
                                    autoComplete={"off"}
                                    onTextChanged={this.onSearchTextChanged}
                                    placeholder={"What are you looking for?"}
                                />
                                <div className={styles.filter_dropdown_container}>
                                    <FilterBar
                                        defaultGenres={this.state.genres}
                                        defaultSortBy={this.state.sortBy}
                                        defaultListingStates={this.state.listingStates}
                                        onSortByChanged={this.onSortByChanged}
                                        onGenresChanged={this.onGenresChanged}
                                        onListingStateChanged={this.onListingStateChanged}
                                        onReset={this.onReset}
                                    />
                                </div>
                            </div>
                        </TextArea>
                    </ContentArea>
                    <ContentArea>
                        <br />
                        <br />
                        <MasonryTiles
                            contentEndpoint={"/content/filtered"}
                            contentEndpointParams={{
                                sortBy: this.state.sortBy,
                                genres: JSON.stringify(this.state.genres),
                                listingStates: JSON.stringify(this.state.listingStates),
                                search: this.state.search,
                                includeCollections: false,
                            }}
                            windowSize={this.state.windowSize}
                            showExploreMarket={false}
                            showTileData={true}
                            showTileDataOverlay={false}
                            onBid={this.onShowBidOverlay}
                        />
                    </ContentArea>
                    <NavPanel showScrollTop={true} />
                </Main>
            );
        }
    }
);
