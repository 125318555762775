// extracted by mini-css-extract-plugin
export var collapseButton = "pageSplit-module--collapseButton--xZgSa";
export var collapsed = "pageSplit-module--collapsed--ajiMe";
export var container = "pageSplit-module--container--LUWBE";
export var contentContainer = "pageSplit-module--contentContainer--6woMa";
export var sideContainer = "pageSplit-module--sideContainer--Kzb8w";
export var sideContent = "pageSplit-module--sideContent--dtj1u";
export var sideFloat = "pageSplit-module--sideFloat--epzpl";
export var sideHeader = "pageSplit-module--sideHeader--1NGoR";
export var sticky = "pageSplit-module--sticky--L6Wgh";
export var topContainer = "pageSplit-module--topContainer--RezkL";
export var topContainerInner = "pageSplit-module--topContainerInner--OgYY4";
export var v_desktop = "pageSplit-module--v_desktop--bkFHr";