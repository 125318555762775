import React from "react";
import { Link } from "gatsby";
import * as styles from "./pageSplit.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import Button from "./button";

export default class PageSplit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: this.props.collapsed === true,
        };
    }

    componentDidMount() {}

    onToggleCollapse = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        return (
            <div className={styles.container}>
                <div
                    className={`
                    ${styles.sideContainer}
                    ${this.state.collapsed ? styles.collapsed : null}
                    v_desktop_flex
                `}
                >
                    <div
                        className={`
                        ${styles.sideFloat}
                        ${this.props.sideSticky === true ? styles.sticky : null}
                    `}
                    >
                        {this.props.collapsible !== false && (
                            <div className={styles.sideHeader}>
                                <div className={styles.collapseButton} onClick={this.onToggleCollapse}>
                                    <FontAwesomeIcon icon={this.state.collapsed ? faArrowRight : faArrowLeft} />
                                </div>
                                {this.state.collapsed && this.props.sideHeader && <h6 className={"caps_and_spaced no_wrap rotate_90_trans_50"}>{this.props.sideHeader}</h6>}
                            </div>
                        )}
                        {!this.state.collapsed && <div className={styles.sideContent}>{this.props.sideContent}</div>}
                    </div>
                </div>
                <div className={styles.contentContainer}>{this.props.children}</div>
                <div
                    className={`
                    ${styles.topContainer}
                    ${this.state.collapsed ? styles.collapsed : null}
                    v_tablet
                    v_mobile
                `}
                >
                    <div className={styles.topContainerInner}>
                        {!this.state.collapsed && <div className={styles.sideContent}>{this.props.sideContent}</div>}
                        {this.props.collapsible !== false && (
                            <div className={styles.sideHeader}>
                                {this.state.collapsed && this.props.sideHeader && <h6 className={"caps_and_spaced no_wrap"}>{this.props.sideHeader}</h6>}
                                <div className={styles.collapseButton} onClick={this.onToggleCollapse}>
                                    <FontAwesomeIcon icon={this.state.collapsed ? faArrowDown : faArrowUp} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
