import React from "react";
import { Link } from "gatsby";
import * as styles from "./filterBar.module.css";
import FilterBarSection from "./filterBarSection";
import DropdownMenu from "./dropdownMenu";
import ListWithButtons from "./listWithButtons";
import Api from "../config/api";
import Common from "../config/common";
import Util from "../config/util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faClock } from "@fortawesome/free-regular-svg-icons";
import { faArrowLeft, faDollarSign, faChevronDown, faArrowRight, faArrowDown, faArrowUp, faMusic, faBars, faArrowUpZA } from "@fortawesome/free-solid-svg-icons";
import Button from "./button";

export default class FilterBar extends React.Component {
    constructor(props) {
        super(props);
        this.apiDelayTimer = null;
        this.state = {
            apiDelayMs: 1000,
            genres: {},
            sortBy: [
                {
                    key: "HIGHEST_PRICE",
                    display: "Highest Priced",
                    icon: faArrowUp,
                },
                {
                    key: "LOWEST_PRICE",
                    display: "Lowest Priced",
                    icon: faArrowDown,
                },
                {
                    key: "RECENTLY_ADDED",
                    display: "Recently Added",
                    icon: faClock,
                },
                // {
                //     key: "MOST_LIKED",
                //     display: "Most Liked",
                //     icon: faHeart,
                // },
            ],
            listingStates: [
                {
                    key: "NOT_FOR_SALE",
                    display: "Not For Sale",
                },
                {
                    key: "FIXED_PRICE_SALE",
                    display: "Fixed Price",
                },
                {
                    key: "AUCTION_SALE",
                    display: "Auction Sale",
                },
            ],

            selectedGenres: this.props.defaultGenres || [],
            selectedSortBy: this.props.defaultSortBy || "RECENTLY_ADDED",
            selectedListingStates: this.props.defaultListingStates || ["FIXED_PRICE_SALE", "AUCTION_SALE"],
            selectedPriceMin: -1,
            selectedPriceMax: -1,

            lastSentGenres: null,
            lastSentSortBy: null,
            lastSentListingStates: null,
            lastSentPriceMin: null,
            lastSentPriceMax: null,
        };

        // Set out last sent values to be the same as what we received
        this.state.lastSentGenres = this.state.selectedGenres;
        this.state.lastSentSortBy = this.state.selectedSortBy;
        this.state.lastSentListingStates = this.state.selectedListingStates;
        this.state.lastSentPriceMin = this.state.selectedPriceMin;
        this.state.lastSentPriceMax = this.state.selectedPriceMax;
    }

    componentDidMount() {
        Api.market({
            endpoint: "/genre",
            method: "GET",
            data: {
                ...(this.props.subGenre && { name: this.props.subGenre }),
            },
        })
            .then((res) => {
                if (this.props.subGenre) {
                    this.setState({
                        genres: res.genres[this.props.subGenre],
                    });
                } else {
                    let _genres = {};
                    for (let key in res.genres) {
                        let contentCount = 0;
                        let artistCount = 0;
                        for (let subKey in res.genres[key]) {
                            contentCount += res.genres[key][subKey].contentCount;
                            artistCount += res.genres[key][subKey].artistCount;
                        }
                        _genres[key] = {
                            display: Common.capitalise(key),
                            contentCount,
                            artistCount,
                        };
                    }
                    this.setState({
                        genres: _genres,
                    });
                }
            })
            .catch((e) => {
                console.error(e);
                Util.notify.error("Ran into an issue - you may experience some missing functionality. Try restarting.");
            });
    }

    // onPriceChanged={this.onPriceChanged}

    onSelectSortBy = (key) => {
        clearTimeout(this.apiDelayTimer);
        this.setState(
            {
                selectedSortBy: key,
            },
            () => {
                this.apiDelayTimer = setTimeout(() => {
                    if (this.state.lastSentSortBy !== this.state.selectedSortBy) {
                        this.setState(
                            {
                                lastSentSortBy: this.state.selectedSortBy,
                            },
                            () => {
                                this.props.onSortByChanged && this.props.onSortByChanged(this.state.selectedSortBy);
                            }
                        );
                    }
                }, this.state.apiDelayMs);
            }
        );
    };
    onSelectGenres = (key) => {
        clearTimeout(this.apiDelayTimer);
        let already_selected = this.state.selectedGenres.indexOf(key) > -1;
        let new_genres = already_selected ? this.state.selectedGenres.filter((g) => g !== key) : [...this.state.selectedGenres, key];
        this.setState(
            {
                selectedGenres: new_genres,
            },
            () => {
                this.apiDelayTimer = setTimeout(() => {
                    if (!Common.equals(this.state.selectedGenres, this.state.lastSentGenres)) {
                        this.setState(
                            {
                                lastSentGenres: this.state.selectedGenres,
                            },
                            () => {
                                this.props.onGenresChanged && this.props.onGenresChanged(this.state.selectedGenres);
                            }
                        );
                    }
                }, this.state.apiDelayMs);
            }
        );
    };
    onSelectListingState = (key) => {
        clearTimeout(this.apiDelayTimer);
        let already_selected = this.state.selectedListingStates.indexOf(key) > -1;
        let new_states = already_selected ? this.state.selectedListingStates.filter((g) => g !== key) : [...this.state.selectedListingStates, key];
        this.setState(
            {
                selectedListingStates: new_states,
            },
            () => {
                this.apiDelayTimer = setTimeout(() => {
                    if (!Common.equals(this.state.selectedListingStates, this.state.lastSentListingStates)) {
                        this.setState(
                            {
                                lastSentListingStates: this.state.selectedListingStates,
                            },
                            () => {
                                this.props.onListingStateChanged && this.props.onListingStateChanged(this.state.selectedListingStates);
                            }
                        );
                    }
                }, this.state.apiDelayMs);
            }
        );
    };

    onClearListingStates = () => {
        this.setState(
            {
                selectedListingStates: [],
            },
            () => {
                this.apiDelayTimer = setTimeout(() => {
                    if (!Common.equals(this.state.selectedListingStates, this.state.lastSentListingStates)) {
                        this.setState(
                            {
                                lastSentListingStates: this.state.selectedListingStates,
                            },
                            () => {
                                this.props.onListingStateChanged && this.props.onListingStateChanged(this.state.selectedListingStates);
                            }
                        );
                    }
                }, this.state.apiDelayMs);
            }
        );
    };
    onClearGenres = () => {
        this.setState(
            {
                selectedGenres: [],
            },
            () => {
                this.apiDelayTimer = setTimeout(() => {
                    if (!Common.equals(this.state.selectedGenres, this.state.lastSentGenres)) {
                        this.setState(
                            {
                                lastSentGenres: this.state.selectedGenres,
                            },
                            () => {
                                this.props.onGenresChanged && this.props.onGenresChanged(this.state.selectedGenres);
                            }
                        );
                    }
                }, this.state.apiDelayMs);
            }
        );
    };

    renderButton = (text, icon) => {
        return (
            <span className={styles.filter_button}>
                <span className={styles.filter_button_icon}>
                    <FontAwesomeIcon icon={icon} />
                </span>
                <span className={styles.filter_button_text}>
                    <span>
                        {text}
                        <span className={styles.chevron}>
                            <FontAwesomeIcon icon={faChevronDown} />
                        </span>
                    </span>
                </span>
            </span>
        );
    };

    render() {
        return (
            <div className={`${styles.container} ${styles.pinned} ${this.props.darkMode === true ? styles.dark_mode : ""}`}>
                <div className={styles.inner_container}>
                    <DropdownMenu
                        iconComponent={this.renderButton("Listing States", faDollarSign)}
                        darkMode={this.props.darkMode === true}
                        items={[
                            {
                                type: "section",
                                noPadding: true,
                                content: (
                                    <ListWithButtons
                                        darkMode={this.props.darkMode === true}
                                        listItems={this.state.listingStates.map((item) => {
                                            return {
                                                text: item.display,
                                                onClick: () => {
                                                    this.onSelectListingState(item.key);
                                                },
                                                selected: this.state.selectedListingStates.indexOf(item.key) > -1,
                                            };
                                        })}
                                        buttons={[
                                            {
                                                style: 1,
                                                text: "Clear",
                                                onClick: this.onClearListingStates,
                                            },
                                            // {
                                            //     style: 2,
                                            //     text: "Apply",
                                            //     onClick: this.onApplyListingStates,
                                            // },
                                        ]}
                                    />
                                ),
                            },
                        ]}
                    />
                    <DropdownMenu
                        iconComponent={this.renderButton("Genres", faBars)}
                        darkMode={this.props.darkMode === true}
                        items={[
                            {
                                type: "section",
                                noPadding: true,
                                content: (
                                    <ListWithButtons
                                        darkMode={this.props.darkMode === true}
                                        listItems={Object.keys(this.state.genres).map((optionKey) => {
                                            return {
                                                text: `${this.state.genres[optionKey].display} (${this.state.genres[optionKey].artistCount})`,
                                                selected: this.state.selectedGenres.indexOf(optionKey) > -1,
                                                onClick: () => {
                                                    this.onSelectGenres(optionKey);
                                                },
                                            };
                                        })}
                                        buttons={[
                                            {
                                                style: 1,
                                                text: "Clear",
                                                onClick: this.onClearGenres,
                                            },
                                            // {
                                            //     style: 2,
                                            //     text: "Apply",
                                            //     onClick: this.onApplyGenres,
                                            // },
                                        ]}
                                    />
                                ),
                            },
                        ]}
                    />
                    {/* <DropdownMenu
                        iconComponent={this.renderButton("Price", faDollarSign)}
                        items={[
                            {
                                type: "item",
                                name: `All`,
                                onClick: () => {},
                            },
                            ...Object.keys(this.state.genres).map((optionKey) => {
                                return {
                                    type: "item",
                                    name: `${this.state.genres[optionKey].display} (${this.state.genres[optionKey].artistCount})`,
                                    onClick: () => {},
                                };
                            }),
                        ]}
                    /> */}
                    <DropdownMenu
                        iconComponent={this.renderButton("Sort By", faArrowUpZA)}
                        darkMode={this.props.darkMode === true}
                        pin={"right"}
                        items={[
                            {
                                type: "section",
                                noPadding: true,
                                content: (
                                    <ListWithButtons
                                        darkMode={this.props.darkMode === true}
                                        listItems={this.state.sortBy.map((option) => {
                                            return {
                                                text: option.display,
                                                onClick: () => {
                                                    this.onSelectSortBy(option.key);
                                                },
                                                selected: this.state.selectedSortBy === option.key,
                                            };
                                        })}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>

                {/* {this.props.sections.map(
                    (section, sec_index) =>
                        section.key && (
                            <FilterBarSection key={`sec_${sec_index}`} header={section.header || ""} flexWrap={true}>
                                {section.items.map((item, item_index) => (
                                    <Button
                                        key={`sec_${sec_index}_item_${item_index}`}
                                        style={{ marginTop: 5, marginRight: 10, marginBottom: 5, marginLeft: 0 }}
                                        displayMode={this.state.selected[section.key].indexOf(item.name) > -1 ? 5 : 3}
                                        hoverMode={this.state.selected[section.key].indexOf(item.name) > -1 ? 1 : 3}
                                        onClick={() => {
                                            this.onItemSelected(section, item);
                                        }}
                                        text={item.name}
                                        icon={item.icon}
                                    />
                                ))}
                            </FilterBarSection>
                        )
                )} */}
            </div>
        );
    }
}
